<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 타입
            </p>
            <b-form-radio-group
              v-model="query.viewType"
            >
              <b-form-radio value="summary">
                요약
              </b-form-radio>
              <b-form-radio value="detail">
                상세
              </b-form-radio>
            </b-form-radio-group>
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기준
            </p>
            <b-form-radio-group
              v-model="query.searchType"
            >
              <b-form-radio :value="1">
                의원
              </b-form-radio>
              <b-form-radio :value="2">
                의사
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
            <label style="width: 140px">(*당일데이터 조회 불가)</label>
            <b-form-input
              v-model="query.liveInfoId"
              placeholder="ID검색"
              class="el-def"
              @keyup.enter="fetchData()"
            />
          </b-col>
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <summary-grid
        v-if="viewType === 'summary'"
        :data="drugInfoData"
        :search-type="searchType"
      />

      <detail-grid
        v-else-if="viewType === 'detail'"
        :data="drugInfoData"
        :search-type="searchType"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import SummaryGrid from './grid/SummaryGrid.vue'
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    SummaryGrid,
    DetailGrid,
  },

  setup() {
    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      viewType: 'summary',
      searchType: 1,
      clientId: null,
      liveInfoId: null,
      status: null,
      searchStartDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      searchEndDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    })

    const viewType = ref("summary")
    const searchType = ref(1)
    const drugInfoData = ref([])

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      if (Math.abs(frDate.diff(toDate, 'day')) > 90) {
        pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
        return
      }

      axios.get(`/fa/statistics/drug/${query.value.viewType}`, {
        params: {
          searchType: query.value.searchType,
          startDate: query.value.searchStartDate,
          endDate: query.value.searchEndDate,
          liveInfoId: query.value.liveInfoId,
        },
      })
        .then(rs => {
          viewType.value = query.value.viewType
          searchType.value = query.value.searchType

          drugInfoData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      if (viewType.value === 'summary') summaryDownload()
      else if (viewType.value === 'detail') detailDownload()
    }

    const summaryDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 15 },
        { header: "제약사", key: "clientName", width: 25 },
        { header: "제목", key: "title", width: 50 },
        { header: "게시 기간", key: "liveDate", width: 25 },
        { header: "진료과", key: "medicalDept", width: 20 },
        {
          header: "총 클릭 수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: searchType.value === 1 ? "총 클릭 처수" : "총 클릭 명수", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "알림창 제외 수", key: "neverAgainViewCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "댓글 수", key: "commentCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "방문 신청 수", key: "visitCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "샘플 신청 수", key: "sampleCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "이메일 답변 수", key: "emailCount", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      drugInfoData.value.map(e => {
        e.summaryDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          liveDate: `${dayjs(e.startDate).format('YYYY-MM-DD')} ~ ${dayjs(e.endDate).format('YYYY-MM-DD')}`,
          medicalDept: x.medicalDept,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          neverAgainViewCount: x.neverAgainViewCount,
          commentCount: x.commentCount,
          visitCount: x.visitCount,
          sampleCount: x.sampleCount,
          emailCount: x.emailCount,
        }))

        worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          liveDate: `${dayjs(e.startDate).format('YYYY-MM-DD')} ~ ${dayjs(e.endDate).format('YYYY-MM-DD')}`,
          medicalDept: '소계',
          totalClickCount: e.summaryDepts.reduce((f, l) => f + l.totalClickCount, 0),
          groupClickCount: e.summaryDepts.reduce((f, l) => f + l.groupClickCount, 0),
          neverAgainViewCount: e.summaryDepts.reduce((f, l) => f + l.neverAgainViewCount, 0),
          commentCount: e.summaryDepts.reduce((f, l) => f + l.commentCount, 0),
          visitCount: e.summaryDepts.reduce((f, l) => f + l.visitCount, 0),
          sampleCount: e.summaryDepts.reduce((f, l) => f + l.sampleCount, 0),
          emailCount: e.summaryDepts.reduce((f, l) => f + l.emailCount, 0),
        })

        endRow += e.summaryDepts.length

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "약품정보 통계(요약).xlsx")
    }

    const detailDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '제약사'
      worksheet.getCell('C1').value = '제목'
      worksheet.getCell('D1').value = '게시 기간'
      worksheet.getCell('E1').value = '회원 구분'
      worksheet.getCell('F1').value = '진료과'

      worksheet.getCell('G1').value = '모아보기'
      worksheet.mergeCells(`G1:H1`)

      worksheet.getCell('I1').value = '처방팝업'
      worksheet.mergeCells(`I1:N1`)

      worksheet.getCell('O1').value = '스케줄팝업'
      worksheet.mergeCells(`O1:R1`)

      worksheet.getCell('S1').value = '닥터인포'
      worksheet.mergeCells(`S1:T1`)

      worksheet.getCell('U1').value = '총 클릭 수'
      worksheet.getCell('V1').value = searchType.value === 1 ? '총 클릭 처수' : '총 클릭 명수'
      worksheet.getCell('W1').value = '알림창 제외 수'
      worksheet.getCell('X1').value = '댓글 수'
      worksheet.getCell('Y1').value = '방문 신청 수'
      worksheet.getCell('Z1').value = '샘플 신청 수'
      worksheet.getCell('AA1').value = '이메일 답변 신청 수'

      worksheet.getCell('G2').value = '클릭 수'
      worksheet.getCell('H2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('I2').value = '새처방'
      worksheet.getCell('J2').value = '재처방'
      worksheet.getCell('K2').value = '노출 수'
      worksheet.getCell('L2').value = searchType.value === 1 ? '노출 처수' : '노출 명수'
      worksheet.getCell('M2').value = '클릭 수'
      worksheet.getCell('N2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('O2').value = '노출 수'
      worksheet.getCell('P2').value = searchType.value === 1 ? '노출 처수' : '노출 명수'
      worksheet.getCell('Q2').value = '클릭 수'
      worksheet.getCell('R2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'
      worksheet.getCell('S2').value = '클릭 수'
      worksheet.getCell('T2').value = searchType.value === 1 ? '클릭 처수' : '클릭 명수'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`U1:U2`)
      worksheet.mergeCells(`V1:V2`)
      worksheet.mergeCells(`W1:W2`)
      worksheet.mergeCells(`X1:X2`)
      worksheet.mergeCells(`Y1:Y2`)
      worksheet.mergeCells(`Z1:Z2`)
      worksheet.mergeCells(`AA1:AA2`)

      worksheet.columns = [
        { key: "id", width: 15 },
        { key: "clientName", width: 25 },
        { key: "title", width: 50 },
        { key: "liveDate", width: 10 },
        { key: "memberType", width: 10 },
        { key: "medicalDept", width: 20 },
        { key: "totalMoaClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupMoaClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "prescriptionNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "prescriptionReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPrescriptionOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalPrescriptionClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupPrescriptionClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupScheduleOpenCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalScheduleClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupScheduleClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "neverAgainViewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "commentCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "visitCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "sampleCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "emailCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      drugInfoData.value.map(e => {
        e.memberDetailDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          liveDate: `${dayjs(e.startDate).format('YYYY-MM-DD')} ~ ${dayjs(e.endDate).format('YYYY-MM-DD')}`,
          memberType: '회원',
          medicalDept: x.medicalDept,
          totalMoaClickCount: x.totalMoaClickCount,
          groupMoaClickCount: x.groupMoaClickCount,
          prescriptionNewCount: x.prescriptionNewCount,
          prescriptionReCount: x.prescriptionReCount,
          totalPrescriptionOpenCount: x.totalPrescriptionOpenCount,
          groupPrescriptionOpenCount: x.groupPrescriptionOpenCount,
          totalPrescriptionClickCount: x.totalPrescriptionClickCount,
          groupPrescriptionClickCount: x.groupPrescriptionClickCount,
          totalScheduleOpenCount: x.totalScheduleOpenCount,
          groupScheduleOpenCount: x.groupScheduleOpenCount,
          totalScheduleClickCount: x.totalScheduleClickCount,
          groupScheduleClickCount: x.groupScheduleClickCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          neverAgainViewCount: x.neverAgainViewCount,
          commentCount: x.commentCount,
          visitCount: x.visitCount,
          sampleCount: x.sampleCount,
          emailCount: x.emailCount,
        }))

        if (e.memberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            liveDate: '',
            memberType: '소계',
            medicalDept: '',
            totalMoaClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            prescriptionNewCount: e.memberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.memberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            totalScheduleOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            totalDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0),
            neverAgainViewCount: e.memberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0),
            commentCount: e.memberDetailDepts.reduce((f, l) => f + l.commentCount, 0),
            visitCount: e.memberDetailDepts.reduce((f, l) => f + l.visitCount, 0),
            sampleCount: e.memberDetailDepts.reduce((f, l) => f + l.sampleCount, 0),
            emailCount: e.memberDetailDepts.reduce((f, l) => f + l.emailCount, 0),
          })

          worksheet.mergeCells(`E${endRow}:E${endRow + e.memberDetailDepts.length - 1}`)

          endRow += e.memberDetailDepts.length

          worksheet.mergeCells(`E${endRow}:F${endRow}`)

          if (e.noneMemberDetailDepts.length > 0) endRow += 1
        }

        e.noneMemberDetailDepts.map(x => worksheet.addRow({
          id: e.id,
          clientName: e.clientName,
          title: e.title,
          liveDate: `${dayjs(e.startDate).format('YYYY-MM-DD')} ~ ${dayjs(e.endDate).format('YYYY-MM-DD')}`,
          memberType: '비회원',
          medicalDept: x.medicalDept,
          totalMoaClickCount: x.totalMoaClickCount,
          groupMoaClickCount: x.groupMoaClickCount,
          prescriptionNewCount: x.prescriptionNewCount,
          prescriptionReCount: x.prescriptionReCount,
          totalPrescriptionOpenCount: x.totalPrescriptionOpenCount,
          groupPrescriptionOpenCount: x.groupPrescriptionOpenCount,
          totalPrescriptionClickCount: x.totalPrescriptionClickCount,
          groupPrescriptionClickCount: x.groupPrescriptionClickCount,
          totalScheduleOpenCount: x.totalScheduleOpenCount,
          groupScheduleOpenCount: x.groupScheduleOpenCount,
          totalScheduleClickCount: x.totalScheduleClickCount,
          groupScheduleClickCount: x.groupScheduleClickCount,
          totalDrInfoClickCount: x.totalDrInfoClickCount,
          groupDrInfoClickCount: x.groupDrInfoClickCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          neverAgainViewCount: x.neverAgainViewCount,
          commentCount: x.commentCount,
          visitCount: x.visitCount,
          sampleCount: x.sampleCount,
          emailCount: x.emailCount,
        }))

        if (e.noneMemberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            liveDate: '',
            memberType: '소계',
            medicalDept: '',
            totalMoaClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            prescriptionNewCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            totalScheduleOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            totalDrInfoClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0),
            neverAgainViewCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0),
            commentCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.commentCount, 0),
            visitCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.visitCount, 0),
            sampleCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.sampleCount, 0),
            emailCount: e.noneMemberDetailDepts.reduce((f, l) => f + l.emailCount, 0),
          })

          worksheet.mergeCells(`E${endRow}:E${endRow + e.noneMemberDetailDepts.length - 1}`)

          endRow += e.noneMemberDetailDepts.length

          worksheet.mergeCells(`E${endRow}:F${endRow}`)
        }

        if (e.memberDetailDepts.length > 0 || e.noneMemberDetailDepts.length > 0) {
          worksheet.addRow({
            id: e.id,
            clientName: e.clientName,
            title: e.title,
            liveDate: '',
            memberType: '합계',
            medicalDept: '',
            totalMoaClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0),
            groupMoaClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0),
            prescriptionNewCount: e.memberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0),
            prescriptionReCount: e.memberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0),
            totalPrescriptionOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0),
            groupPrescriptionOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0),
            totalPrescriptionClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0),
            groupPrescriptionClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0),
            totalScheduleOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0),
            groupScheduleOpenCount: e.memberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0),
            totalScheduleClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0),
            groupScheduleClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0),
            totalDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0),
            groupDrInfoClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0),
            totalClickCount: e.memberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0),
            groupClickCount: e.memberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0),
            neverAgainViewCount: e.memberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0),
            commentCount: e.memberDetailDepts.reduce((f, l) => f + l.commentCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.commentCount, 0),
            visitCount: e.memberDetailDepts.reduce((f, l) => f + l.visitCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.visitCount, 0),
            sampleCount: e.memberDetailDepts.reduce((f, l) => f + l.sampleCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.sampleCount, 0),
            emailCount: e.memberDetailDepts.reduce((f, l) => f + l.emailCount, 0) + e.noneMemberDetailDepts.reduce((f, l) => f + l.emailCount, 0),
          })

          endRow += 1
          worksheet.mergeCells(`E${endRow}:F${endRow}`)
        }

        worksheet.mergeCells(`A${startRow}:A${endRow}`)
        worksheet.mergeCells(`B${startRow}:B${endRow}`)
        worksheet.mergeCells(`C${startRow}:C${endRow}`)
        worksheet.mergeCells(`D${startRow}:D${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "약품정보 통계(상세).xlsx")
    }

    return {
      query,
      viewType,
      searchType,
      drugInfoData,
      fetchData,
      excelDownload,
      summaryDownload,
      detailDownload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
