<template>
  <div>
    <b-table-simple
      bordered
      responsive
      sticky-header
      style="max-height: 65vh; font-size: 12px"
    >
      <colgroup>
        <col style="width: 95px">
        <col style="width: 150px">
        <col style="width: 250px">
        <col style="width: 140px">
        <col style="width: 90px">
        <col style="width: 140px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 110px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
        <col style="width: 100px">
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 0"
          >
            ID
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 95px;"
          >
            제약사
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 245px;"
          >
            제목
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 495px;"
          >
            게시 기간
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 635px;"
          >
            회원<br>구분
          </b-th>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 725px;"
          >
            진료과
          </b-th>
          <b-th colspan="2">
            모아보기
          </b-th>
          <b-th colspan="6">
            처방팝업
          </b-th>
          <b-th colspan="4">
            스케줄팝업
          </b-th>
          <b-th colspan="2">
            닥터인포
          </b-th>
          <b-th rowspan="2">
            총 클릭 수
          </b-th>
          <b-th rowspan="2">
            {{ searchType === 1 ? '총 클릭 처수' : '총 클릭 명수' }}
          </b-th>
          <b-th rowspan="2">
            알림창<br>제외 수
          </b-th>
          <b-th rowspan="2">
            댓글 수
          </b-th>
          <b-th rowspan="2">
            방문<br>신청 수
          </b-th>
          <b-th rowspan="2">
            샘플<br>신청 수
          </b-th>
          <b-th rowspan="2">
            이메일<br>답변 신청 수
          </b-th>
        </b-tr>
        <b-tr>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '클릭 처수' : '클릭 명수' }}
          </b-th>
          <b-th style="top: 38.55px;">
            새처방
          </b-th>
          <b-th style="top: 38.55px;">
            재처방
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '노출 처수' : '노출 명수' }}
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '클릭 처수' : '클릭 명수' }}
          </b-th>
          <b-th style="top: 38.55px;">
            노출 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '노출 처수' : '노출 명수' }}
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '클릭 처수' : '클릭 명수' }}
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            {{ searchType === 1 ? '클릭 처수' : '클릭 명수' }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="detail in data">
          <template v-for="(member, index) in detail.memberDetailDepts">
            <b-tr
              :key="`member-${detail.id}-${index}`"
            >
              <b-td
                v-if="index === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 0"
              >
                {{ detail.id }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 95px"
              >
                {{ detail.clientName }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 245px"
              >
                {{ detail.title }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 495px"
              >
                {{ detail.startDate | $dateFormatter('YYYY-MM-DD') }} ~<br>{{ detail.endDate | $dateFormatter('YYYY-MM-DD') }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="detail.memberDetailDepts.length"
                class="sticky-row"
                style="left: 635px"
              >
                회원
              </b-td>
              <b-td
                class="sticky-row"
                style="left: 725px"
              >{{ member.medicalDept }}</b-td>
              <b-td>{{ member.totalMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.prescriptionNewCount | $numberFormatter }}</b-td>
              <b-td>{{ member.prescriptionReCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.neverAgainViewCount | $numberFormatter }}</b-td>
              <b-td>{{ member.commentCount | $numberFormatter }}</b-td>
              <b-td>{{ member.visitCount | $numberFormatter }}</b-td>
              <b-td>{{ member.sampleCount | $numberFormatter }}</b-td>
              <b-td>{{ member.emailCount | $numberFormatter }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="detail.memberDetailDepts.length > 0"
            :key="`member-total-${detail.id}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 635px;"
            >
              소계
            </b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.commentCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.visitCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.sampleCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.memberDetailDepts.reduce((f, l) => f + l.emailCount, 0) | $numberFormatter }}</b-td>
          </b-tr>
          <template v-for="(member, index) in detail.noneMemberDetailDepts">
            <b-tr
              :key="`nonemember-${detail.id}-${index}`"
            >
              <b-td
                v-if="index === 0 && detail.memberDetailDepts.length === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 0"
              >
                {{ detail.id }}
              </b-td>
              <b-td
                v-if="index === 0 && detail.memberDetailDepts.length === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 95px"
              >
                {{ detail.clientName }}
              </b-td>
              <b-td
                v-if="index === 0 && detail.memberDetailDepts.length === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 245px"
              >
                <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                  {{ detail.title }}
                </div>
              </b-td>
              <b-td
                v-if="index === 0 && detail.memberDetailDepts.length === 0"
                :rowspan="(detail.memberDetailDepts.length > 0 ? detail.memberDetailDepts.length + 1 : 0) + (detail.noneMemberDetailDepts.length > 0 ? detail.noneMemberDetailDepts.length + 1 : 0) + 1"
                class="sticky-row"
                style="left: 495px"
              >
                {{ detail.startDate | $dateFormatter('YYYY-MM-DD') }} ~<br>{{ detail.endDate | $dateFormatter('YYYY-MM-DD') }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="detail.noneMemberDetailDepts.length"
                class="sticky-row"
                style="left: 635px"
              >
                비회원
              </b-td>
              <b-td
                class="sticky-row"
                style="left: 725px"
              >{{ member.medicalDept }}</b-td>
              <b-td>{{ member.totalMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupMoaClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.prescriptionNewCount | $numberFormatter }}</b-td>
              <b-td>{{ member.prescriptionReCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupPrescriptionOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupPrescriptionClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupScheduleOpenCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupScheduleClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupDrInfoClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.totalClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.groupClickCount | $numberFormatter }}</b-td>
              <b-td>{{ member.neverAgainViewCount | $numberFormatter }}</b-td>
              <b-td>{{ member.commentCount | $numberFormatter }}</b-td>
              <b-td>{{ member.visitCount | $numberFormatter }}</b-td>
              <b-td>{{ member.sampleCount | $numberFormatter }}</b-td>
              <b-td>{{ member.emailCount | $numberFormatter }}</b-td>
            </b-tr>
          </template>
          <b-tr
            v-if="detail.noneMemberDetailDepts.length > 0"
            :key="`nonemember-total-${detail.id}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 635px;"
            >
              소계
            </b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.commentCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.visitCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.sampleCount, 0) | $numberFormatter }}</b-td>
            <b-td>{{ detail.noneMemberDetailDepts.reduce((f, l) => f + l.emailCount, 0) | $numberFormatter }}</b-td>
          </b-tr>
          <b-tr
            :key="`all-total-${detail.id}`"
          >
            <b-td
              colspan="2"
              class="sticky-row"
              style="text-align: center; left: 635px;"
            >
              합계
            </b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalMoaClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupMoaClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionNewCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.prescriptionReCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalPrescriptionClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupPrescriptionClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleOpenCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalScheduleClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupScheduleClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalDrInfoClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupDrInfoClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.totalClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.groupClickCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.neverAgainViewCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.commentCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.commentCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.visitCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.visitCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.sampleCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.sampleCount, 0)) | $numberFormatter }}</b-td>
            <b-td>{{ (detail.memberDetailDepts.reduce((f, l) => f + l.emailCount, 0) + detail.noneMemberDetailDepts.reduce((f, l) => f + l.emailCount, 0)) | $numberFormatter }}</b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    searchType: {
      type: Number,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
