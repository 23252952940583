var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        {
          staticStyle: { "max-height": "65vh", "font-size": "12px" },
          attrs: { bordered: "", responsive: "", "sticky-header": "" },
        },
        [
          _c("colgroup", [
            _c("col", { staticStyle: { width: "95px" } }),
            _c("col", { staticStyle: { width: "150px" } }),
            _c("col", { staticStyle: { width: "250px" } }),
            _c("col", { staticStyle: { width: "140px" } }),
            _c("col", { staticStyle: { width: "90px" } }),
            _c("col", { staticStyle: { width: "140px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "110px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
          ]),
          _c(
            "b-thead",
            { attrs: { "head-variant": "light" } },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "0" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" ID ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "95px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 제약사 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "245px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 제목 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "495px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 게시 기간 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "635px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 회원"), _c("br"), _vm._v("구분 ")]
                  ),
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "725px" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 진료과 ")]
                  ),
                  _c("b-th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 모아보기 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "6" } }, [
                    _vm._v(" 처방팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 스케줄팝업 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 닥터인포 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 총 클릭 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "총 클릭 처수" : "총 클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 알림창"),
                    _c("br"),
                    _vm._v("제외 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 댓글 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 방문"),
                    _c("br"),
                    _vm._v("신청 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 샘플"),
                    _c("br"),
                    _vm._v("신청 수 "),
                  ]),
                  _c("b-th", { attrs: { rowspan: "2" } }, [
                    _vm._v(" 이메일"),
                    _c("br"),
                    _vm._v("답변 신청 수 "),
                  ]),
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 새처방 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 재처방 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "노출 처수" : "노출 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 노출 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "노출 처수" : "노출 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.searchType === 1 ? "클릭 처수" : "클릭 명수"
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-tbody",
            [
              _vm._l(_vm.data, function (detail) {
                return [
                  _vm._l(detail.memberDetailDepts, function (member, index) {
                    return [
                      _c(
                        "b-tr",
                        { key: "member-" + detail.id + "-" + index },
                        [
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "0" },
                                  attrs: {
                                    rowspan:
                                      (detail.memberDetailDepts.length > 0
                                        ? detail.memberDetailDepts.length + 1
                                        : 0) +
                                      (detail.noneMemberDetailDepts.length > 0
                                        ? detail.noneMemberDetailDepts.length +
                                          1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(detail.id) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "95px" },
                                  attrs: {
                                    rowspan:
                                      (detail.memberDetailDepts.length > 0
                                        ? detail.memberDetailDepts.length + 1
                                        : 0) +
                                      (detail.noneMemberDetailDepts.length > 0
                                        ? detail.noneMemberDetailDepts.length +
                                          1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(detail.clientName) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "245px" },
                                  attrs: {
                                    rowspan:
                                      (detail.memberDetailDepts.length > 0
                                        ? detail.memberDetailDepts.length + 1
                                        : 0) +
                                      (detail.noneMemberDetailDepts.length > 0
                                        ? detail.noneMemberDetailDepts.length +
                                          1
                                        : 0) +
                                      1,
                                  },
                                },
                                [_vm._v(" " + _vm._s(detail.title) + " ")]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "495px" },
                                  attrs: {
                                    rowspan:
                                      (detail.memberDetailDepts.length > 0
                                        ? detail.memberDetailDepts.length + 1
                                        : 0) +
                                      (detail.noneMemberDetailDepts.length > 0
                                        ? detail.noneMemberDetailDepts.length +
                                          1
                                        : 0) +
                                      1,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          detail.startDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      " ~"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$dateFormatter")(
                                        detail.endDate,
                                        "YYYY-MM-DD"
                                      )
                                    ) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          index === 0
                            ? _c(
                                "b-td",
                                {
                                  staticClass: "sticky-row",
                                  staticStyle: { left: "635px" },
                                  attrs: {
                                    rowspan: detail.memberDetailDepts.length,
                                  },
                                },
                                [_vm._v(" 회원 ")]
                              )
                            : _vm._e(),
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: { left: "725px" },
                            },
                            [_vm._v(_vm._s(member.medicalDept))]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupMoaClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.prescriptionNewCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.prescriptionReCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupPrescriptionOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupPrescriptionClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupScheduleOpenCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupScheduleClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupDrInfoClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.totalClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.groupClickCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  member.neverAgainViewCount
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(member.commentCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(member.visitCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(member.sampleCount)
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(member.emailCount)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                  detail.memberDetailDepts.length > 0
                    ? _c(
                        "b-tr",
                        { key: "member-total-" + detail.id },
                        [
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: {
                                "text-align": "center",
                                left: "635px",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v(" 소계 ")]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalMoaClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupMoaClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.prescriptionNewCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.prescriptionReCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalPrescriptionOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupPrescriptionOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalPrescriptionClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupPrescriptionClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalScheduleOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupScheduleOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalScheduleClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupScheduleClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalDrInfoClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupDrInfoClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.neverAgainViewCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.commentCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.visitCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.sampleCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.memberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.emailCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(
                    detail.noneMemberDetailDepts,
                    function (member, index) {
                      return [
                        _c(
                          "b-tr",
                          { key: "nonemember-" + detail.id + "-" + index },
                          [
                            index === 0 && detail.memberDetailDepts.length === 0
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-row",
                                    staticStyle: { left: "0" },
                                    attrs: {
                                      rowspan:
                                        (detail.memberDetailDepts.length > 0
                                          ? detail.memberDetailDepts.length + 1
                                          : 0) +
                                        (detail.noneMemberDetailDepts.length > 0
                                          ? detail.noneMemberDetailDepts
                                              .length + 1
                                          : 0) +
                                        1,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(detail.id) + " ")]
                                )
                              : _vm._e(),
                            index === 0 && detail.memberDetailDepts.length === 0
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-row",
                                    staticStyle: { left: "95px" },
                                    attrs: {
                                      rowspan:
                                        (detail.memberDetailDepts.length > 0
                                          ? detail.memberDetailDepts.length + 1
                                          : 0) +
                                        (detail.noneMemberDetailDepts.length > 0
                                          ? detail.noneMemberDetailDepts
                                              .length + 1
                                          : 0) +
                                        1,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(detail.clientName) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            index === 0 && detail.memberDetailDepts.length === 0
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-row",
                                    staticStyle: { left: "245px" },
                                    attrs: {
                                      rowspan:
                                        (detail.memberDetailDepts.length > 0
                                          ? detail.memberDetailDepts.length + 1
                                          : 0) +
                                        (detail.noneMemberDetailDepts.length > 0
                                          ? detail.noneMemberDetailDepts
                                              .length + 1
                                          : 0) +
                                        1,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                          "white-space": "nowrap",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(detail.title) + " ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            index === 0 && detail.memberDetailDepts.length === 0
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-row",
                                    staticStyle: { left: "495px" },
                                    attrs: {
                                      rowspan:
                                        (detail.memberDetailDepts.length > 0
                                          ? detail.memberDetailDepts.length + 1
                                          : 0) +
                                        (detail.noneMemberDetailDepts.length > 0
                                          ? detail.noneMemberDetailDepts
                                              .length + 1
                                          : 0) +
                                        1,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("$dateFormatter")(
                                            detail.startDate,
                                            "YYYY-MM-DD"
                                          )
                                        ) +
                                        " ~"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          detail.endDate,
                                          "YYYY-MM-DD"
                                        )
                                      ) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            index === 0
                              ? _c(
                                  "b-td",
                                  {
                                    staticClass: "sticky-row",
                                    staticStyle: { left: "635px" },
                                    attrs: {
                                      rowspan:
                                        detail.noneMemberDetailDepts.length,
                                    },
                                  },
                                  [_vm._v(" 비회원 ")]
                                )
                              : _vm._e(),
                            _c(
                              "b-td",
                              {
                                staticClass: "sticky-row",
                                staticStyle: { left: "725px" },
                              },
                              [_vm._v(_vm._s(member.medicalDept))]
                            ),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalMoaClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupMoaClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.prescriptionNewCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.prescriptionReCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalPrescriptionOpenCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupPrescriptionOpenCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalPrescriptionClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupPrescriptionClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalScheduleOpenCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupScheduleOpenCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalScheduleClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupScheduleClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalDrInfoClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupDrInfoClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.totalClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.groupClickCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.neverAgainViewCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    member.commentCount
                                  )
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(member.visitCount)
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(member.sampleCount)
                                )
                              ),
                            ]),
                            _c("b-td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(member.emailCount)
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    }
                  ),
                  detail.noneMemberDetailDepts.length > 0
                    ? _c(
                        "b-tr",
                        { key: "nonemember-total-" + detail.id },
                        [
                          _c(
                            "b-td",
                            {
                              staticClass: "sticky-row",
                              staticStyle: {
                                "text-align": "center",
                                left: "635px",
                              },
                              attrs: { colspan: "2" },
                            },
                            [_vm._v(" 소계 ")]
                          ),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalMoaClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupMoaClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.prescriptionNewCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.prescriptionReCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalPrescriptionOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupPrescriptionOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalPrescriptionClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupPrescriptionClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalScheduleOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupScheduleOpenCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalScheduleClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupScheduleClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalDrInfoClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupDrInfoClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.totalClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.groupClickCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.neverAgainViewCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.commentCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.visitCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.sampleCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                          _c("b-td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("$numberFormatter")(
                                  detail.noneMemberDetailDepts.reduce(function (
                                    f,
                                    l
                                  ) {
                                    return f + l.emailCount
                                  },
                                  0)
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-tr",
                    { key: "all-total-" + detail.id },
                    [
                      _c(
                        "b-td",
                        {
                          staticClass: "sticky-row",
                          staticStyle: {
                            "text-align": "center",
                            left: "635px",
                          },
                          attrs: { colspan: "2" },
                        },
                        [_vm._v(" 합계 ")]
                      ),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalMoaClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalMoaClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupMoaClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupMoaClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.prescriptionNewCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.prescriptionNewCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.prescriptionReCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.prescriptionReCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalPrescriptionOpenCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalPrescriptionOpenCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupPrescriptionOpenCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupPrescriptionOpenCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalPrescriptionClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalPrescriptionClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupPrescriptionClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupPrescriptionClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalScheduleOpenCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalScheduleOpenCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupScheduleOpenCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupScheduleOpenCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalScheduleClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalScheduleClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupScheduleClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupScheduleClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalDrInfoClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalDrInfoClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupDrInfoClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupDrInfoClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.totalClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.totalClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.groupClickCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.groupClickCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.neverAgainViewCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.neverAgainViewCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.commentCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.commentCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.visitCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.visitCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.sampleCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.sampleCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              detail.memberDetailDepts.reduce(function (f, l) {
                                return f + l.emailCount
                              }, 0) +
                                detail.noneMemberDetailDepts.reduce(function (
                                  f,
                                  l
                                ) {
                                  return f + l.emailCount
                                },
                                0)
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }